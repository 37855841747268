<template>
  <transition name="fade" mode="in-out">
    <section class="upload">
      <md-dialog-alert
        :md-active.sync="uploaded"
        md-title="Processing started..."
        md-content="
        Your face model will soon be ready! Check its status at free will, or let us check for you.
        More importantly: DO NOT QUIT THE PAGE!
      "
      />
      <LeavePageConfirmation />

      <div class="progress-bar" v-if="queuing && !isQueueDone">
        <span class="md-title">{{ progressAmount }}%</span>
        <md-progress-bar
          md-mode="determinate"
          :md-value="progressAmount">
        </md-progress-bar>
      </div>

      <div class="upload-section" v-if="demo">
        <transition name="component-fade" mode="out-in">
          <Uploader @uploadCompleted="onComplete" v-bind:is="view"/>
        </transition>
      </div>

      <queue-component v-if="queuing"></queue-component>

      <div class="md-layout md-alignment-center-space-around button-wrapper"
           v-if="isQueueDone && getZipDirectory">
        <button @click.prevent="onConvert"
                :disabled="!getZipDirectory"
                class="raised-btn gradient-action-btn">
          {{ downloading ? 'Downloading...' : 'Download your 3D files (~13MB) 🚀'}}
        </button>
        <button class="raised-btn gradient-action-btn no-pulse"
                @click.prevent="onVisualize">
          Visualize Latest Results🔬
        </button>
      </div>

      <CustomRequest v-if="!queuing"/>

    </section>
  </transition>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Fac3dMain',
  components: {
    Uploader: () => import('@/components/Uploader.vue'),
    QueueComponent: () => import('@/components/Queue.vue'),
    LeavePageConfirmation: () => import('@/components/LeavePageConfirm.vue'),
    CustomRequest: () => import('@/components/CustomRequest.vue'),
  },
  computed: {
    ...mapGetters([
      'getUserImage', 'getIsUserLoggedIn', 'getZipDirectory',
      'getRemainingQuota', 'getInstanceError', 'isUserSubscribed',
      'getQueueError', 'isQueueDone',
    ]),
  },
  data() {
    return {
      view: 'uploader',
      demo: false,
      downloading: false,
      visualizing: false,
      uploaded: false,
      queuing: false,
      progressAmount: 0,
    };
  },
  methods: {
    onConvert() {
      if (!this.getUserImage) {
        this.$store.dispatch('setAlert', {
          show: true,
          title: 'Not allowed',
          content: 'Please upload an image first!',
        });
        return false;
      }
      if (this.getZipDirectory.length !== 0) {
        this.downloading = true;
        this.$store.commit('DECREMENT_QUOTA');
        return this.$store.dispatch('downloadZipFiles').then(() => {
          this.downloading = false;
          // this.$store.commit('SET_ZIP_DOWNLOAD', null);
          this.demo = false;
          this.$store.dispatch('setAlert', {
            show: true,
            title: 'Thank you for the download!',
            content: '',
          });
        }, () => {
          this.downloading = false;
        });
      }
      return this.$store.dispatch('setAlert', {
        show: true,
        title: 'Your file is in the queue!',
        content: 'Click the button below to check its status.',
      });
    },
    onRetry() {
      this.$store.commit('SET_ZIP_DOWNLOAD', null);
      this.demo = !this.demo;
      this.$nextTick(() => {
        this.demo = !this.demo;
      });
    },
    async onVisualize() {
      // this.visualizing = true;
      await this.$router.push({ name: 'Fac3dVisualizer' });
    },
    onComplete() {
      this.demo = false;
      this.uploaded = true;
      this.queuing = true;
    },
  },
  created() {
    this.demo = true;
  },
  watch: {
    queuing(val) {
      if (val) {
        setInterval(() => {
          if (this.progressAmount < 100) this.progressAmount += 14;
        }, 8000);
      } else {
        this.progressAmount = 0;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.getUserImage && to.name !== 'Fac3dVisualizer') {
      next(false);
      this.$store.commit('SET_CONFIRMATION_POPUP', true);
      this.$on('confirmLeave', (res) => {
        if (res) {
          this.$store.commit('SET_IMAGE', null);
          this.$store.commit('SET_QUEUE_ERROR', null);
          this.$store.commit('SET_QUEUE', false);
          next();
        } else next(false);
      });
    } else {
      this.$store.commit('SET_CONFIRMATION_POPUP', false);
      this.$store.commit('SET_IMAGE', null);
      this.$store.commit('SET_QUEUE_ERROR', null);
      this.$store.commit('SET_QUEUE', false);
      next();
    }
  },
};
</script>
<style lang="scss" scoped>
section.upload {
  position: relative;
  z-index: 11;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.upload-section {
  margin: 0 auto 3rem;
  //filter: drop-shadow(6px 4px 4px rgba(57, 40, 166, 0.3));
  // border: 1px solid white;
  padding: 1rem;
  // background: rgba(255, 255, 255, 0.75);
  //backdrop-filter: blur(80px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 34px;
  max-width: 75%;
}
.upload-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.progress-bar {
  padding: 1rem;
}
@media only screen and (max-width: 520px) {
  .button-wrapper {
    flex-direction: column !important;
    justify-content: space-between !important;
    align-items: center !important;
    button {
      margin-bottom: 30px;
    }
  }
}
</style>
